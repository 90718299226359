import {obterApi} from "./Api"

const api = () => {
    return obterApi(process.env.REACT_APP_API_URL + '/Empresa')
}

export const listarConveniosEmpresa = async (id) => {
    return api().get(`convenios?id=${id}`)
}

export const obterEmpresasColaboradorPorUsuarioId = async () => {
    return api().get(`por-usuario`)
}

export const obterEmpresaPorId = async (id) => {
    return api().get(`${id}`)
}

export const atualizarEmpresa = async (empresa) => {
    return api().put(`${empresa.id}`, empresa)
}

export const obterUnidadesPermissoes = async (colaboradorId, empresaId) => {
    return api().get(`unidades-permissoes?colaboradorId=${colaboradorId}&empresaId=${empresaId}`)
}

export const conveniosEmpresaPaginado = async (idEmpresa, page, pageSize, busca) => {
    return api().get(`convenios-paginado?empresaId=${idEmpresa}&PageNumber=${page}&PageSize=${pageSize}&Busca=${busca}`)
}

export const definirPermissaoUnidade = async (model) => {
    return api().put(`unidades-permissoes`, model)
}

export const atualizarUnidadePadrao = async (colaboradorId, unidadeId) => {
    return api().put(`unidade-padrao?colaboradorId=${colaboradorId}&unidadeId=${unidadeId}`)
}

export const cadastrarColaboradorUnidades = async (colaboradorId, empresaId) => {
    return api().post(`unidades-permissoes/novo-colaborador?colaboradorId=${colaboradorId}&empresaId=${empresaId}`)
}

export const alterarEmpresaIntegrador = async (empresaId, bool) => {
    return api().get(`empresa-autorizador/${empresaId}/${bool}`)
}

export const alterarEmpresaTiss = async (empresaId, bool) => {
    return api().get(`empresa-tiss/${empresaId}/${bool}`)
}

export const VerificarAtivacaoAutorizador = async (empresaId) => {
    return api().get(`verificar-ativacao-autorizador/${empresaId}`)
}