// ** Initial State
const initialState = {
  data: []
  // total: 1,
  // params: {},
  // selectedUser: null
}

const procedimentos = (state = initialState, action) => {
  switch (action.type) {
    case 'OBTER_PROCEDIMENTOS':
      return { ...state, data: action.data, loading: action.loading }
    // case 'GET_DATA':
    //   return {
    //     ...state,
    //     data: action.data,
    //     total: action.totalPages,
    //     params: action.params
    //   }
    // case 'GET_USER':
    //   return { ...state, selectedUser: action.selectedUser }
    // case 'ADD_USER':
    //   return { ...state }
    // case 'DELETE_USER':
    //   return { ...state }
    default:
      return { ...state }
  }
}
export default procedimentos
