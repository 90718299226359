import {obterApi} from "./Api"

const api = () => {
    return obterApi(process.env.REACT_APP_API_URL + '/TeleAtendimento')
}


export const criarSala = (model) => {
  return api().post(`criar-sala`, model)
}

export const startStopRecord = (sid, record) => {
  //record = true || false;
  return api().put(`start-stop-record/${sid}?record=${record}`)
}

export const encerrarSala = (sid) => {
  return api().post(`encerrar/${sid}`)
}

export const getTokenPaciente = (roomName, pacienteNome) => {
  return api().get(`obter-token-paciente?roomName=${roomName}&pacienteNome=${pacienteNome}`)
}