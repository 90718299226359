import { salvarPrescricaoSalva } from "../../../services/Memed";

function iniciarMemed(dados, token, atendimentoId, dadosMedico, dadosUnidade) {
  MdSinapsePrescricao.event.add('core:moduleInit', function moduleInitHandler(module) {
    MdHub.module.show('plataforma.prescricao')
    // O módulo da prescrição foi iniciado
    if (module.name === 'plataforma.prescricao') {
      // Registrando o evento de click no elemento
      // document.getElementById("botaoShowPrescricao").addEventListener("click", function () {
      MdHub.command.send('plataforma.prescricao', 'setPaciente', {
        // Nome do paciente (obrigatório)
        nome: dados.nome,
        // Endereço do paciente (opcional)
        endereco: dados.telefone ? dados.telefone : '-----',
        // Cidade do paciente (opcional)
        cidade: dados.cidade ? dados.cidade : '-----',
        // Telefone (opcional, DDD + digitos, somente números)
        telefone: dados.telefone,
        // Usado no receituário de alto custo (Opcional)
        peso: dados.peso,
        // Usado no receituário de alto custo (Opcional)
        altura: dados.altura,
        // Usado no receituário de alto custo (Opcional)
        nome_mae: dados.nomeMae,
        // Usado no receituário de alto custo (Opcional)
        dificuldade_locomocao: false,
        // Pode ser um documento criptografado do paciente, por exemplo
        // Usamos essa propriedade para destinguir nomes iguais
        idExterno: dados.idPaciente,
        cpf: dados.cpf,
        data_nascimento: dados.data_nascimento
      }).then(function success() {
        MdHub.command.send('plataforma.prescricao', 'setFeatureToggle', {
          // Desativa a opção de excluir um paciente (obrigatório)
          deletePatient: false,
          // Desabilita a opção de remover/trocar o paciente (obrigatório)
          removePatient: false,
          // Esconde o histórico de prescrições
          historyPrescription: true,
          // Esconde o botão "Nova Prescrição"
          newPrescription: true,
          // Esconde o botão "Opções Receituário"
          optionsPrescription: true,
          // Desabilita a opção de remover a prescrição no histórico
          removePrescription: false,
          // Esconde o formulário de edição do paciente
          editPatient: false,
          // Desabilita a opção para definir alergias para o paciente
          setPatientAllergy: true,
          // Desabilita a aba "Exames" do Autocomplete de medicamentos
          autocompleteExams: true,
          // Desabilita a aba "Industrializados" do Autocomplete de medicamentos
          autocompleteIndustrialized: true,
          // Desabilita a aba "Manipulados" do Autocomplete de medicamentos
          autocompleteManipulated: true,
          // Desabilita a aba "Composições" do Autocomplete de medicamentos
          autocompleteCompositions: true,
          // Desabilita a aba "Periféricos" do Autocomplete de medicamentos
          autocompletePeripherals: true,
          // Esconde o botão "Copiar para Prontuário" (que copia o conteúdo da prescrição para o clipboard)
          copyMedicalRecords: true,
          // Esconde o botão de fechar da prescrição
          buttonClose: false,
          // Habilita opção Nova Fórmula Manipulada no menu
          newFormula: true,
          // Desativa o componente de Novas Formas de envio da Receita 
          allowShareModal: false,
          // Desabilita o modal de conclusao de fluxo
          conclusionModal: false,
          // Define se o guia de apresentação do módulo será exibido na primeira vez que o prescritor acessa a plataforma
          guidesOnboarding: false,
          // Controla se é permitido editar ou não os dados já preenchidos nos campos da tela de identificação de médico e paciente no fluxo de prescrição
          editIdentification: false,
          // Permite que o prescritor visualize o menu de ajuda.
          showHelpMenu: false,
        })
        MdHub.command.send('plataforma.prescricao', 'setWorkplace', {
          city: dadosMedico.pessoa.endereco.cidade.nome ? dadosMedico.pessoa.endereco.cidade.nome : '-----',
          state: dadosMedico.pessoa.endereco.cidade.estado.sigla ? dadosMedico.pessoa.endereco.cidade.estado.sigla : '-----',
          cnes: dadosUnidade.cnes ? dadosUnidade.cnes : '---',
          local_name: dadosUnidade.pessoa.nome ? dadosUnidade.pessoa.nome : '-----',
          address: dadosMedico.pessoa.endereco.logradouro ? dadosMedico.pessoa.endereco.logradouro : '-----',
          phone: dadosMedico.pessoa.telefone1 ? dadosMedico.pessoa.telefone1.lenght > 11 ? dadosMedico.pessoa.telefone1 : Number(dadosMedico.pessoa.telefone1) : '----------'
        });
        MdHub.module.show('plataforma.prescricao')
      })

      MdHub.event.add('prescricaoSalva', async function prescricaoSalvaCallback(idPrescricao) {
        // Aqui é possível enviar esse ID para seu back-end obter mais informações sobre a prescrição.
        // obterPrescricaoSalva(idPrescricao);
        try {
          const resp = await salvarPrescricaoSalva(idPrescricao, token, atendimentoId)
          if (resp) {
            console.log('Prescrição salva com sucesso!')
          }
        } catch (error) {
          console.log('Erro ao salvar prescrição!', error.message)
        }
      });

      MdHub.event.add('prescricaoExcluida', function prescricaoSalvaCallback(idPrescricao) {
        // Aqui é possível enviar esse ID para seu back-end obter mais informações sobre a prescrição.
        console.log(idPrescricao, 'exclui a prescrição')
      });

      // MdHub.event.add('prescricaoImpressa', function (prescriptionData) {
      //   var element = document.getElementById("botaoprint");
      //   element.style.display = "block";
      // });
    }
  });
}

function finalizar() {
  MdHub.command.send('plataforma.sdk', 'logout');
  // Parar os event listeners da Memed
  MdHub.server.unbindEvents();
  // Remover o objeto principal da Memed
  MdHub = null;
  // Injetar novamente o script seguindo o passo 2 acima
}

export { iniciarMemed, finalizar }