const ComponentSpinner = props => {
  const { fixed, blank = false, size = false } = props

  return (
    <div className={blank ? 'fallback-spinner background bg-white' : `fallback-spinner background${fixed ? ' fixed' : ''}`}>
      <div className='loading'>
        <div className='effect-1 effects'></div>
        <div className='effect-2 effects'></div>
        <div className='effect-3 effects'></div>
      </div>
    </div>
  )
}

export default ComponentSpinner
