// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { handleLoading } from '../../../redux/loader'
import { 
    AtendimentoAtual, 
    AtualizarAtendimento, 
    AtualizarRespostaProntuario, 
    CriarAtendimento, 
    SalvarRespostaProntuario } from '../../../services/Atendimentos'


export const obterPorId = createAsyncThunk('atendimento/obter', async id => {
    const response = await AtendimentoAtual(id)
    return {
        anamnese: response.data?.anamnese,
        anamneseId: response.data?.anamneseId,
        anexosAtendimento: response.data?.anexosAtendimento,
        colaborador: response.data?.colaborador,
        colaboradorId: response.data?.colaboradorId,
        condutaAdotada: response.data?.condutaAdotada,
        condutaAdotadaId: response.data?.condutaAdotadaId,
        dataAtendimento: response.data?.dataAtendimento,
        dataFimAtendimento: response.data?.dataFimAtendimento,
        empresa: response.data?.empresa,
        empresaId: response.data?.empresaId,
        exameFisico: response.data?.exameFisico,
        exameFisicoId: response.data?.exameFisicoId,
        hipoteseDiagnostica: response.data?.hipoteseDiagnostica,
        hipoteseDiagnosticaId: response.data?.hipoteseDiagnosticaId,
        prescricao: response.data?.prescricao,
        prescricaoId: response.data?.prescricaoId,
        id: response.data?.id,
        paciente: response.data?.paciente,
        pacienteId: response.data?.pacienteId,
        procedimentosRealizados: response.data?.procedimentosRealizados,
        unidade: response.data?.unidade,
        unidadeId: response.data?.unidadeId
    }
})

export const obterAtendimentoAtual = createAsyncThunk('atendimento/atender', async id => {
    const response = await AtendimentoAtual(id)
    return {
        anamnese: response.data?.anamnese,
        anamneseId: response.data?.anamneseId,
        anexosAtendimento: response.data?.anexosAtendimento,
        colaborador: response.data?.colaborador,
        colaboradorId: response.data?.colaboradorId,
        condutaAdotada: response.data?.condutaAdotada,
        condutaAdotadaId: response.data?.condutaAdotadaId,
        dataAtendimento: response.data?.dataAtendimento,
        dataFimAtendimento: response.data?.dataFimAtendimento,
        empresa: response.data?.empresa,
        empresaId: response.data?.empresaId,
        exameFisico: response.data?.exameFisico,
        exameFisicoId: response.data?.exameFisicoId,
        hipoteseDiagnostica: response.data?.hipoteseDiagnostica,
        hipoteseDiagnosticaId: response.data?.hipoteseDiagnosticaId,
        prescricao: response.data?.prescricao,
        prescricaoId: response.data?.prescricaoId,
        id: response.data?.id,
        paciente: response.data?.paciente,
        pacienteId: response.data?.pacienteId,
        procedimentosRealizados: response.data?.procedimentosRealizados,
        unidade: response.data?.unidade,
        unidadeId: response.data?.unidadeId
    }
})

export const atualizar = createAsyncThunk('atendimento/atualizar', async (atendimento, { dispatch, getState }) => {
    const response = await AtualizarAtendimento(atendimento)
    await dispatch(obterAtendimentoAtual(atendimento?.id))
    return response.data
})

export const criar = createAsyncThunk('atendimento/criar', async (atendimento, { dispatch, getState }) => {
    const response = await CriarAtendimento(atendimento)
    await dispatch(obterAtendimentoAtual(response?.data?.id))
    return response.data
})

export const personalizado = createAsyncThunk('atendimento/personalizado', async (atendimento, { dispatch, getState }) => {
    const response = await SalvarRespostaProntuario(atendimento)
    await dispatch(obterAtendimentoAtual(response?.data?.atendimentoId))
    return response.data
})

export const personalizado_atualizar = createAsyncThunk('atendimento/personalizado_atualizar', async (atendimento, { dispatch, getState }) => {
    const response = await AtualizarRespostaProntuario(atendimento)
    await dispatch(obterAtendimentoAtual(response?.data?.atendimentoId))
    return response.data
})

const handleAtendimento = (state, action) => {
    localStorage.setItem('atendimento', JSON.stringify(action.payload))
    state.anamnese = action.payload.anamnese
    state.anamneseId = action.payload.anamneseId
    state.anexosAtendimento = action.payload.anexosAtendimento
    state.colaborador = action.payload.colaborador
    state.colaboradorId = action.payload.colaboradorId
    state.condutaAdotada = action.payload.condutaAdotada
    state.condutaAdotadaId = action.payload.condutaAdotadaId
    state.dataAtendimento = action.payload.dataAtendimento
    state.dataFimAtendimento = action.payload.dataFimAtendimento
    state.empresa = action.payload.empresa
    state.empresaId = action.payload.empresaId
    state.exameFisico = action.payload.exameFisico
    state.exameFisicoId = action.payload.exameFisicoId
    state.hipoteseDiagnostica = action.payload.hipoteseDiagnostica
    state.hipoteseDiagnosticaId = action.payload.hipoteseDiagnosticaId
    state.prescricao = action.payload.prescricao
    state.prescricaoId = action.payload.prescricaoId
    state.id = action.payload.id
    state.paciente = action.payload.paciente
    state.pacienteId = action.payload.pacienteId
    state.procedimentosRealizados = action.payload.procedimentosRealizados
    state.unidade = action.payload.unidade
    state.unidadeId = action.payload.unidadeId
}

const initAtendimento = () => {
    const item = window.localStorage.getItem('atendimento')
    return item ? JSON.parse(item) : {
        anamnese: {},
        anamneseId: null,
        anexosAtendimento: [],
        colaborador: {},
        colaboradorId: null,
        condutaAdotada: {},
        condutaAdotadaId: null,
        dataAtendimento: null,
        dataFimAtendimento: null,
        empresa: {},
        empresaId: null,
        exameFisico: {},
        exameFisicoId: null,
        hipoteseDiagnostica: {},
        hipoteseDiagnosticaId: null,
        prescricao: {},
        prescricaoId: null,
        id: null,
        paciente: {},
        pacienteId: null,
        procedimentosRealizados: [],
        unidade: {},
        unidadeId: null,
        personalizados: []
    }
}

export const atendimentoSlice = createSlice({
    name: 'atendimento',
    initialState: initAtendimento(),
    reducers: {
        handleFinalizarAtendimento: (state, action) => {
            localStorage.removeItem('atendimento')
            state.id = action.payload
        }
    },
    extraReducers: builder => {
        builder
            .addCase(obterPorId.fulfilled, (state, action) => {
                handleAtendimento(state, action)
            })
            .addCase(obterAtendimentoAtual.fulfilled, (state, action) => {
                handleAtendimento(state, action)
            })
    }
})

export const { handleFinalizarAtendimento } = atendimentoSlice.actions

export default atendimentoSlice.reducer
