import { Ability, AbilityBuilder } from "@casl/ability"

export const getAbilities = (user, colaborator) => {
  const { can, rules } = new AbilityBuilder(Ability)
  
  can('view', 'all')

  // if (user?.claims.find(claim => claim.value === 'configuracoes')) {
  //   can('view', 'all')
  // } else {
  //   if (colaborator?.tipo === "M") {
  //     can('view', 'Doctor')
  //   } else {
  //     // const roles = user?.roles ? user?.roles : user?.userToken?.roles
  //     // roles?.forEach(role => {
  //     //   can('view', role)
  //     // });
  //     can('view', 'Doctor')
  //   }
  // }
  
  return rules
}

