import { obterApi } from "./Api"

const api = () => {
    return obterApi(process.env.REACT_APP_API_URL + '/Atendimento')
}

export const listarAtendimentosPaciente = async (idPaciente) => {
    return api().get(`listar?idPaciente=${idPaciente}`)
}

export const CriarAtendimento = async (model) => {
    return api().post(`atendimento`, model)
}

export const EnviarQuestionarioPreConsulta = async (colaboradorId, agendaId) => {
    return api().get(`enviar-questionario-pre-consulta/${colaboradorId}/${agendaId}`)
}

export const ReenviarQuestionarioPreConsulta = async (agendaId) => {
    return api().get(`reenviar-questionario-pre-consulta/${agendaId}`)
}

export const VerificarFormularioSalvo = async (colaboradorId) => {
    return api().get(`pre-consulta/${colaboradorId}`)
}

export const SalvarFormularioPreConsulta = async (model) => {
    return api().post(`formulario-pre-consulta`, model)
}

export const AtualizarAtendimento = async (model) => {
    return api().post(`atualizar`, model)
}

export const FinalizarAtendimento = async (id) => {
    return api().get(`finalizar?id=${id}`)
}

export const AtendimentoAtual = async (id) => {
    return api().get(`atendimento?id=${id}`)
}

export const AddProcedimento = async (model) => {
    return api().post(`procedimento`, model)
}

export const ExcluirProcedimento = async (id) => {
    return api().delete(`procedimento/${id}`)
}

export const CadastrarConduta = async (model) => {
    return api().post(`salvarConduta`, model)
}

export const GerarArquivoExportacao = async (tipo, model) => {
    return api().post(`exportar-dados?tipo=${tipo}`, model)
}

export const GerarContaAzul = async (model) => {
    return api().post(`atendimentos-financeiro`, model)
}

export const AtualizarConduta = async (model) => {
    return api().post(`atualizarConduta`, model)
}

export const CadastrarAnamnese = async (model) => {
    return api().post(`salvarAnamnese`, model)
}

export const AtualizarAnamnese = async (model) => {
    return api().post(`atualizarAnamnese`, model)
}

export const CadastrarHipoteseDiagnostica = async (model) => {
    return api().post(`salvarHipoteseDiagnostica`, model)
}

export const AtualizarHipoteseDiagnostica = async (model) => {
    return api().post(`atualizarHipoteseDiagnostica`, model)
}

export const CadastrarExameFisico = async (model) => {
    return api().post(`salvarExameFisico`, model)
}

export const AtualizarExameFisico = async (model) => {
    return api().post(`atualizarExameFisico`, model)
}

export const AtualizarAvaliacao = async (model) => {
    return api().put(`avaliacao/${model?.id}`, model)
}

export const SalvarSpeechs = async (files, avaliacaoId, empresaId, pacienteId) => {
    const formData = new FormData();
    if (files?.length > 0) {
        files.forEach(item => {
            formData.append('files', item)
        })
    }
    return api().post(`speechs?avaliacaoId=${avaliacaoId}&empresaId=${empresaId}&pacienteId=${pacienteId}`, formData)
}

export const obterAvaliacoesPorAtendimentoId = async (id, avaliacao) => {
    return api().get(`${id}/avaliacoes?avaliacao=${avaliacao}`);
}

export const obterSpeechsPorPaciente = async (pacienteId) => {
    return api().get(`${pacienteId}/speechs`);
}

export const getFileSpeech = async (id) => {
    return api().get(`speechs/${id}`, { responseType: 'blob' })
}

export const CadastrarVacina = async (model) => {
    return api().post(`salvarVacina`, model)
}

export const AtualizarVacina = async (model) => {
    return api().post(`atualizarVacina`, model)
}

export const FichaMedicaPaciente = async (id) => {
    return api().get(`ficha/${id}`)
}

export const TotalAtendimentosEmpresa = async (id) => {
    return api().get(`total?id=${id}`)
}

export const TimeLinePaciente = async (id) => {
    return api().get(`ultimos?id=${id}`)
}

export const PesquisarTimeLinePaciente = async (id, search) => {
    return api().get(`pesquisartimeline?id=${id}&search=${search}`)
}

export const ListarFuncionalidadesAtendimento = async (idEmpresa, idUnidade, idColaborador) => {
    return api().get(`funcionalidades?idEmpresa=${idEmpresa}&idUnidade=${idUnidade}&idColaborador=${idColaborador}`)
}

export const ListarFuncionalidadesAtendimentoPersonalizado = async (idEmpresa, idColaborador, idPaciente) => {
    return api().get(`funcionalidades-prontuario?idEmpresa=${idEmpresa}&idColaborador=${idColaborador}&idPaciente=${idPaciente}`)
}

export const ObterAtendimentoPersonalizadoResposta = async (id, prontuarioPersonalizadoId, idAgenda) => {
    return api().get(`resposta-prontuario?id=${id}&prontuarioPersonalizadoId=${prontuarioPersonalizadoId}&idAgenda=${idAgenda}`)
}

export const AtualizarRespostaProntuario = async (model) => {
    return api().post(`atualizar-resposta-prontuario`, model)
}

export const SalvarRespostaProntuario = async (model) => {
    return api().post(`salvar-resposta-prontuario`, model)
}

export const ReagruparFuncionalidades = async (model) => {
    return api().post(`reagrupar`, model)
}

export const AtualizarFuncionalidade = async (model) => {
    return api().post(`atualizarFuncionalidade`, model)
}

export const FiltarAtendimentos = async (model) => {
    return api().post(`atendimentos-filtro`, model)
}

export const ProcedimentosRealizadosPaciente = async (id) => {
    return api().get(`realizados?id=${id}`)
}

export const PaginacaoTimeLinePaciente = async (id, search, page) => {
    return api().get(`paginacao?id=${id}&page=${page}&search=${search}`)
}

export const ListarTeleAtendimentosComGravacao = async (idPaciente, colaboradorId, search, page) => {
    return api().get(`teleatendimentos-com-gravacao?pacienteId=${idPaciente}&colaboradorId=${colaboradorId}&page=${page}&search=${search}`)
}

export const ObterGravacao = async (gravacaoId) => {
    return api().get(`obter-gravacao-transcricao?gravacaoId=${gravacaoId}`)
}

export const ObterConteudoProntuario = async (model) => {
    return api().post(`prontuario`, model)
}

export const BaixarPrescricao = async (id) => {
    return api().get(`download/${id}`)
}

export const AnexosPrescricaoMevo = async (id) => {
    return api().get(`anexos-mevo/${id}`)
}

export const PesquisaCids = async (nome) => {
    return api().get(`cids?nome=${nome}`)
}

export const listarCidsSelect = async (take) => {
    return api().get(`cids-select?take=${take}`)
}

export const ModelosDocumentosProntuario = async (idEmpresa) => {
    return api().get(`documentos?idEmpresa=${idEmpresa}`)
}

export const salvarPrescricaoAtendimento = async (atendimentoId, model) => {
    return api().post(`${atendimentoId}/salvar-prescricao`, model)
}

export const ObterQuestionarioPreConsulta = async (questionarioId) => {
    return api().get(`obter-questionario-pre-consulta/${questionarioId}`)
}

export const SalvarQuestionarioPreConsulta = async (model) => {
    return api().post(`salvar-questionario-pre-consulta`, model)
}

export const AtualizarQuestionarioPreConsulta = async (model) => {
    return api().put(`atualizar-questionario-pre-consulta`, model)
}
