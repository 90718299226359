// ** Initial State
const initialState = {
    data: []
    // total: 1,
    // params: {},
    // selectedUser: null
}

const orcamentos = (state = initialState, action) => {
    switch (action.type) {
        case 'OBTER_ORCAMENTOS_EMPRESA':
            return { ...state, data: action.data, loading: action.loading }
        case 'OBTER_ORCAMENTOS_UNIDADE':
            return { ...state, data: action.data, loading: action.loading }
        case 'OBTER_ORCAMENTOS_PACIENTE':
            return { ...state, data: action.data, loading: action.loading }
        case 'OBTER_ORCAMENTO_POR_ID':
            return { ...state, data: action.data, loading: action.loading }
        case 'ATUALIZAR_ORCAMENTO':
            return { ...state, data: action.data, loading: action.loading }
        case 'CADASTRAR_ORCAMENTO':
            return { ...state, data: action.data, loading: action.loading }
        default:
            return { ...state }
    }
}
export default orcamentos
