// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {
  AgendarPaciente,
  AlterarTarefa,
  CancelarAgendamento,
  ExcluirTarefaAgenda,
  ListarTarefasPorUnidade,
  MontarHorarios,
  ObterAgendasPorData,
  SalvarTarefa,
  UpdateAgendaPaciente,
  SalvarBloqueioAgenda,
  AgendarPacienteListaEspera,
  ExcluirEventoBloqueioAgenda,
  AlterarSituacaoAgenda,
  UpdateAgendaOnDragPaciente,
  ObterAgendasPorDataPesquisaPaciente,
  ExcluirEventoBloqueioAgendaAllDay
} from '../../../services/Agenda'
import { AtualizarListaEspera, ExcluirListaEspera, ListaEsperaPorUnidade, SalvarListaEspera } from '../../../services/ListaEspera';

export const obterEventosPorData = createAsyncThunk('agenda/eventosPorData', async (model, { dispatch }) => {
  const result = await ObterAgendasPorData(model)
  return result.data;
})

export const obterEventosPorDataPesquisa = createAsyncThunk('agenda/eventosPorDataPesquisa', async (model, { dispatch }) => {
  const result = await ObterAgendasPorDataPesquisaPaciente(model)
  return result.data;
})


export const gerarHorarios = createAsyncThunk('agenda/gerarHorarios', async (model, { dispatch }) => {
  const result = await MontarHorarios(model)
  return result.data;
})

export const obterListaEsperaPorUnidade = createAsyncThunk('agenda/obterListaEsperaPorUnidade', async (idUnidade) => {
  const result = await ListaEsperaPorUnidade(idUnidade)
  return result.data;
})

export const listarTarefas = createAsyncThunk('agenda/listarTarefas', async (idUnidade, { dispatch }) => {
  const result = await ListarTarefasPorUnidade(idUnidade)
  dispatch(salvarUnidadeId(idUnidade))
  return result.data;
})

export const salvarEvento = createAsyncThunk('agenda/salvarEvento', async (event, { dispatch, getState }) => {
  const result = await AgendarPaciente(event)
  const { agenda } = getState();
  await dispatch(obterEventosPorData(agenda?.modelEventosDataSelecionada))
  await dispatch(gerarHorarios(agenda?.modelHorariosDataSelecionada))
  return result.data;
})

export const salvarEventoWaitList = createAsyncThunk('agenda/salvarEventoWaitList', async (event, { dispatch, getState }) => {
  const result = await AgendarPacienteListaEspera(event)
  const { agenda } = getState();
  await dispatch(obterEventosPorData(agenda?.modelEventosDataSelecionada))
  await dispatch(gerarHorarios(agenda?.modelHorariosDataSelecionada))
  return result.data;
})

export const excluirEventoBloqueioAgenda = createAsyncThunk('agenda/excluirEventoBloqueioAgenda', async (idEvento, { dispatch, getState }) => {
  const result = await ExcluirEventoBloqueioAgenda(idEvento)
  const { agenda } = getState();
  await dispatch(obterEventosPorData(agenda?.modelEventosDataSelecionada))
  await dispatch(gerarHorarios(agenda?.modelHorariosDataSelecionada))
  return result.data;
})

export const excluirEventoBloqueioAgendaAllDay = createAsyncThunk('agenda/excluirEventoBloqueioAgendaAllDay', async (model, { dispatch, getState }) => {
  const { idAgenda, idBloqueio } = model;
  const result = await ExcluirEventoBloqueioAgendaAllDay(idAgenda, idBloqueio)
  const { agenda } = getState();
  await dispatch(obterEventosPorData(agenda?.modelEventosDataSelecionada))
  await dispatch(gerarHorarios(agenda?.modelHorariosDataSelecionada))
  return result.data;
})

export const alterarSituacaoAgenda = createAsyncThunk('agenda/alterarSituacaoAgenda', async (model, { dispatch, getState }) => {
  const result = await AlterarSituacaoAgenda(model.idAgenda, model.situacao)
  const { agenda } = getState();
  await dispatch(obterEventosPorData(agenda?.modelEventosDataSelecionada))
  await dispatch(gerarHorarios(agenda?.modelHorariosDataSelecionada))
  return result.data;
})


export const atualizarEvento = createAsyncThunk('agenda/atualizarEvento', async (event, { dispatch, getState }) => {
  const result = await UpdateAgendaPaciente(event)
  const { agenda } = getState();
  await dispatch(obterEventosPorData(agenda?.modelEventosDataSelecionada))
  await dispatch(gerarHorarios(agenda?.modelHorariosDataSelecionada))
  return result.data;
})

export const atualizarEventOnDrag = createAsyncThunk('agenda/atualizarEventOnDrag', async (event, { dispatch, getState }) => {
  const result = await UpdateAgendaOnDragPaciente(event)
  const { agenda } = getState();
  await dispatch(obterEventosPorData(agenda?.modelEventosDataSelecionada))
  await dispatch(gerarHorarios(agenda?.modelHorariosDataSelecionada))
  return result.data;
})

export const removerEvento = createAsyncThunk('agenda/removerEvento', async (idEventos, { dispatch, getState }) => {
  const result = await CancelarAgendamento(idEventos)
  const { agenda } = getState();
  await dispatch(obterEventosPorData(agenda?.modelEventosDataSelecionada))
  await dispatch(gerarHorarios(agenda?.modelHorariosDataSelecionada))
  return result.data;
})

export const adicionarListaEspera = createAsyncThunk('agenda/adicionarListaEspera', async (model, { dispatch, getState }) => {
  const result = await SalvarListaEspera(model)
  await dispatch(obterListaEsperaPorUnidade(model?.unidadeId))
  return result.data;
})

export const atualizarListaEspera = createAsyncThunk('agenda/atualizarListaEspera', async (model, { dispatch, getState }) => {
  const result = await AtualizarListaEspera(model)
  await dispatch(obterListaEsperaPorUnidade(model?.unidadeId))
  return result.data;
})

export const excluirListaEspera = createAsyncThunk('agenda/excluirListaEspera', async ({ id, unidadeId }, { dispatch, getState }) => {
  const result = await ExcluirListaEspera(id)
  await dispatch(obterListaEsperaPorUnidade(unidadeId))
  return result.data;
})

export const adicionarTarefa = createAsyncThunk('agenda/addTarefa', async (model, { dispatch }) => {
  const result = await SalvarTarefa(model)
  await dispatch(listarTarefas(model.unidadeId))
  return result.data;

})

export const atualizarTarefa = createAsyncThunk('agenda/atualizarTarefa', async (model, { dispatch }) => {
  const result = await AlterarTarefa(model)
  await dispatch(listarTarefas(model.unidadeId))
  return result.data;
})

export const removerTarefa = createAsyncThunk('agenda/removerTarefa', async (id, { dispatch, getState }) => {
  const result = await ExcluirTarefaAgenda(id)
  const { agenda } = getState();
  await dispatch(listarTarefas(agenda?.unidadeId))
  return result.data;
})

// bloqueio
export const salvarBloqueio = createAsyncThunk('agenda/salvarBloqueio', async (event, { dispatch, getState }) => {
  const result = await SalvarBloqueioAgenda(event)
  const { agenda } = getState();
  await dispatch(obterEventosPorData(agenda?.modelEventosDataSelecionada))
  await dispatch(gerarHorarios(agenda?.modelHorariosDataSelecionada))
  return result.data;
})

export const agendaSlice = createSlice({
  name: 'agenda',
  initialState: {
    modelHorariosDataSelecionada: {},
    modelEventosDataSelecionada: {},
    eventos: [],
    horarios: [],
    listaEspera: [],
    tarefas: [],
    unidadeId: '',
    selectedEvent: {},
    selectedTask: null,
    selectedCalendars: ['Personal', 'Business', 'Family', 'Holiday', 'ETC']
  },
  reducers: {
    selectEvent: (state, action) => {
      state.selectedEvent = action.payload
    },
    selectTask: (state, action) => {
      state.selectedTask = action.payload
    },
    salvarModelHorariosDataSelecionada: (state, action) => {
      state.modelHorariosDataSelecionada = action.payload
    },
    salvarModelEventosDataSelecionada: (state, action) => {
      state.modelEventosDataSelecionada = action.payload
    },
    salvarUnidadeId: (state, action) => {
      state.unidadeId = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(obterEventosPorData.fulfilled, (state, action) => {
        state.eventos = action.payload
      })
       .addCase(obterEventosPorDataPesquisa.fulfilled, (state, action) => {
        state.eventos = action.payload
      })
      .addCase(gerarHorarios.fulfilled, (state, action) => {
        state.horarios = action.payload
      })
      .addCase(obterListaEsperaPorUnidade.fulfilled, (state, action) => {
        state.listaEspera = action.payload
      })
      .addCase(listarTarefas.fulfilled, (state, action) => {
        state.tarefas = action.payload
      })
  }
})

export const { selectEvent, selectTask, salvarModelHorariosDataSelecionada, salvarModelEventosDataSelecionada, salvarUnidadeId } = agendaSlice.actions

export default agendaSlice.reducer
