// ** Initial State
const initialState = {
    data: []
    // total: 1,
    // params: {},
    // selectedUser: null
}

const modelos = (state = initialState, action) => {
    switch (action.type) {
        case 'OBTER_MODELOS_DOCUMENTOS':
            return { ...state, data: action.data, loading: action.loading }
        case 'OBTER_MODELO_DOCUMENTO_POR_ID':
            return { ...state, data: action.data, loading: action.loading }
        case 'ATUALIZAR_MODELO_DOCUMENTO':
            return { ...state, data: action.data, loading: action.loading }
        case 'CADASTRAR_MODELO_DOCUMENTO':
            return { ...state, data: action.data, loading: action.loading }
        default:
            return { ...state }
    }
}
export default modelos
