// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { atualizarEmpresa, obterEmpresaPorId } from '@services/Empresa'
import { obterEmpresasUnidadesPorUsuario } from '../../../../redux/authentication'


export const obterPorId = createAsyncThunk('empresa/obterPorId', async id => {
  const response = await obterEmpresaPorId(id)
  return {
    empresa: response.data?.empresa,
    unidadeMatriz: response.data?.unidadeMatriz
  }
})

export const atualizar = createAsyncThunk('empresa/atualizar', async (empresa, { dispatch }) => {
  const result = await atualizarEmpresa(empresa)
  await dispatch(obterEmpresasUnidadesPorUsuario())
  return result.data
})

export const empresaSlice = createSlice({
  name: 'empresa',
  initialState: {
    empresas: [],
    empresa: {},
    unidadeMatriz: {},
    dadosGerais: {
      nome: '',
      cpfCnpj: '',
      telefone: '',
      email: '',
      telefone1DDI: ''
    },
  },
  reducers: {
    gravarDadosGerais: (state, action) => {
      state.dadosGerais = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(obterPorId.fulfilled, (state, action) => {
        state.empresa = action.payload.empresa
        state.unidadeMatriz = action.payload.unidadeMatriz
      })
  }
})

export const {
  gravarDadosGerais,
} = empresaSlice.actions

export default empresaSlice.reducer
