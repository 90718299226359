// ** React Imports
import { useState, createContext } from 'react'

// ** Create Context
const GlobalLoader = createContext()

// ** Componentes
import SpinnerComponent from '@components/spinner/Background-spinner'
import FallbackSpinnerComponent from '@components/spinner/Fallback-spinner'

const GlobalLoaderContext = ({ children }) => {
  // ** State
  const [is_loading, setIsLoading] = useState(false)
  const [is_block_loader_active, setIsBlockLoaderActive] = useState(false)

  return (
    <GlobalLoader.Provider value={{ is_loading, setIsLoading, is_block_loader_active, setIsBlockLoaderActive }}>
        {is_loading && <SpinnerComponent fixed />}
        {is_block_loader_active && <FallbackSpinnerComponent fixed />}
        <div className={`${is_block_loader_active ? 'loading-body' : ''}`}>
          {children}
        </div>
    </GlobalLoader.Provider>
  ) 
}

export { GlobalLoader, GlobalLoaderContext }
