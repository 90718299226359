// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const loaderSlice = createSlice({
  name: 'loader',
  initialState: {
    loading: false
  },
  reducers: {
    handleLoading: (state, action) => {
      state.loading = action.payload;
    }
  }
})

export const {
  handleLoading
} = loaderSlice.actions

export default loaderSlice.reducer
