// ** Initial State
const initialState = {
    data: []
    // total: 1,
    // params: {},
    // selectedUser: null
}

const recursos = (state = initialState, action) => {
    switch (action.type) {
        case 'OBTER_RECURSOS':
            return { ...state, data: action.data, loading: action.loading }
        case 'OBTER_RECURSOS_POR_ID':
            return { ...state, data: action.data, loading: action.loading }
        case 'ATUALIZAR_RECURSO':
            return { ...state, data: action.data, loading: action.loading }
        case 'CADASTRAR_RECURSO':
            return { ...state, data: action.data, loading: action.loading }
        // case 'GET_DATA':
        //   return {
        //     ...state,
        //     data: action.data,
        //     total: action.totalPages,
        //     params: action.params
        //   }
        // case 'GET_USER':
        //   return { ...state, selectedUser: action.selectedUser }
        // case 'ADD_USER':
        //   return { ...state }
        // case 'DELETE_USER':
        //   return { ...state }
        default:
            return { ...state }
    }
}
export default recursos
