import {obterApi} from "./Api"

const api = () => {
    return obterApi(process.env.REACT_APP_API_URL + '/ListaEspera')
}

export const ListaEsperaPorUnidade = async (idUnidade) => {
    return api().get(`listarPorUnidade?idUnidade=${idUnidade}`)
}

export const ListaEsperaPorColaborador = async (idColaborador) => {
    return api().get(`listarPorColaborador?idUnidade=${idColaborador}`)
}

export const ObterListaEspera = async (id) => {
    return api().get(`obter/${id}`)
}

export const SairDaListaEspera = async (id) => {
    return api().get(`sair/${id}`)
}

export const ExcluirListaEspera = async (id) => {
    return api().delete(`excluir?id=${id}`)
}

export const SalvarListaEspera = async (model) => {
    return api().post(`salvar`, model)
}

export const AtualizarListaEspera = async (model) => {
    return api().post(`atualizar`, model)
}