// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const registroPlataformaSlice = createSlice({
  name: 'registroPlataforma',
  initialState: {
    
    dadosMedico: {
     nome: '',
     email: '',
     codigoPais: '',
     telefone: '',
     sexo: '',
     crm: '',
     estado: '',
     especialidade: '',
     nomeClinica: '',
     password: '',
     confirmPassword: '',
     cienteRegistroInvalido: false
    },

    dadosOutro: {
      nome: '',
      email: '',
      codigoPais: '',
      telefone: '',
      nomeClinica: '',
      funcao: '',
      password: '',
      confirmPassword: ''  
    },
    tipoUsuario: '',
    tokenValidado: false
 },
  reducers: {
    gravarDadosMedico: (state, action) => {
      state.dadosMedico = action.payload;
    },
    gravarDadosOutro: (state, action) => {
      state.dadosOutro = action.payload;
    },
    selecionarTipoUsuario: (state, action) => {
      state.tipoUsuario = action.payload;
    },
    gravarTokenValidado: (state, action) => {
      state.tokenValidado = action.payload?? false;
    }
  }
})

export const {
  gravarDadosMedico,
  gravarDadosOutro,
  selecionarTipoUsuario,
  gravarTokenValidado
} = registroPlataformaSlice.actions

export default registroPlataformaSlice.reducer
