import { obterApi } from "./Api"

const api = () => {
    return obterApi(process.env.REACT_APP_API_URL + '/Memed')
}

export const obterToken = async (colaboradorId) => {
  return api().get(`colaborador/${colaboradorId}`);
}

export const salvarPrescricaoSalva = async (prescricaoId, token, atendimentoId) => {
  return api().get(`prescricao-salva/${prescricaoId}/${token}/${atendimentoId}`);
}

export const cidadeMemed = async (cidade, uf) => {
  return api().get(`/cidades/${cidade}/${uf}`)
}