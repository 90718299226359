// ** React Imports
import { useState, createContext, useRef, useEffect, useCallback } from 'react'
import { toast } from 'react-toastify'
import { connect, createLocalTracks, LocalVideoTrack } from 'twilio-video'

import CustomToast from '@customComponents/CustomToast'
import { startStopRecord } from '@services/TeleAtendimento'
import { encerrarSala } from '../../services/TeleAtendimento'

// ** Create Context
const Teleatendimento = createContext()

const TeleatendimentoContext = ({ children }) => {

    const timeRef = useRef()

    const localMedia = useRef()
    const remoteMedia = useRef()

    const doctorLocalMedia = useRef()
    const doctorRemoteMedia = useRef()
    const doctorScreenMedia = useRef()

    const [url, setUrl] = useState('')
    const [urlPaciente, setUrlPaciente] = useState('')
    const [roomUniqueName, setRoomUniqueName] = useState('')

    const [is_full_screen, setIsFullScreen] = useState(false)
    
    const [urlWhereby, setUrlWhereby] = useState('')
    const [urlPacienteWhereby, setUrlPacienteWhereby] = useState('')
    const [roomUniqueNameWhereby, setRoomUniqueNameWhereby] = useState('')
    const [activeRoomWhereby, setActiveRoomWhereby] = useState(null)
    const [meetingId, setMeetingId] = useState('')
    
    const [room, setRoom] = useState(null)
    const [token, setToken] = useState(null)
    
    const [timer, setTimer] = useState(0)  
    const [activeRoom, setActiveRoom] = useState(null)
    const [is_room_disconnected, setIsRoomDisconnected] = useState(false)
    
    const [roomNameErr, setRoomNameErr] = useState(false)
    const [identity, setIdentity] = useState('')
    const [is_doctor, setIsDoctor] = useState(false)

    const [is_connecting_room, setIsConnectingRoom] = useState(false)

    const [local_video_track, setLocalVideoTrack] = useState(null)
    const [is_local_video_track_enabled, setIsLocalVideoTrackEnabled] = useState(false)
    
    const [local_audio_track, setLocalAudioTrack] = useState(null)
    const [is_local_audio_track_enabled, setIsLocalAudioTrackEnabled] = useState(false)

    const [local_screen_track, setLocalScreenTrack] = useState(null)
    const [is_local_screen_track_enabled, setIsLocalScreenTrackEnabled] = useState(false)
    const [is_remote_screen_enabled, setIsRemoteScreenEnabled] = useState(false)

    const [is_recording, setIsRecording] = useState(false)

    const [is_remote_media_audio_enabled, setIsRemoteMediaAudioEnabled] = useState(false)
    const [is_remote_media_video_enabled, setIsRemoteMediaVideoEnabled] = useState(false)

    const [screenMedia, setScreenMedia] = useState(null)

    const screenMediaRef = useCallback(node => {
        setScreenMedia(node);
    }, []);
  
    const startTimer = () => {
        const id = setInterval(() => {
            setTimer(prevState => prevState + 1000)
        }, 1000)
        timeRef.current = id
    }    

    const disconnect = (setRoomDisconnection = true) => {
        onDisconect();
        encerrarVideo(setRoomDisconnection);
    }

    const encerrarVideo = async (setRoomDisconnection = true) => {
        try {
            const response = await encerrarSala(room?.sid)
            activeRoom.disconnect();
            if (setRoomDisconnection) setIsRoomDisconnected(true)
            clearRoom();
            clearTimer()
        } catch (error) {
            console.log('erro ao encerrar video', error)
        }
    }

    const clearRoom = () => {
        setActiveRoom(null)
        setLocalVideoTrack(null)
        setIsLocalVideoTrackEnabled(false)
        setLocalAudioTrack(null)
        setIsLocalAudioTrackEnabled(false)
        setRoom(null)
        setToken(null)
    }

    const onDisconect = () => {
        activeRoom.on('disconnected', room => {
            room.localParticipant.tracks.forEach(publication => {
                const track = publication.track;
                if (track.kind == 'video' || track.kind == 'audio') {
                    track.detach().forEach(element => element.remove());
                    track.stop();
                }
                publication.unpublish();
                });
          });
        //   local_video_track.stop()
        //   setLocalVideoTrack(null)
        //   setIsLocalVideoTrackEnabled(false)

        //   local_audio_track.stop()
        //   setLocalAudioTrack(null)
        //   setIsLocalAudioTrackEnabled(false)
    }
    
    const clearTimer = () => {
        clearInterval(timeRef.current)
        timeRef.current = null
        setTimer(0)
    }

    useEffect(() => {
        (async () => await joinRoom())()
    }, [room, token])  

    const joinRoom = async () => {
        setIsConnectingRoom(true)
        if (room && token) {
            if (!room.uniqueName.trim()) {
                setRoomNameErr(true)
                return
            }
    
            let connectOptions = {
                name: room.uniqueName
            }

            if (!local_audio_track && !local_video_track) {
              const tracks = await createTracks()
              if (tracks) {
                  connectOptions.tracks = tracks
              }
            }
            else {
              connectOptions.tracks = [
                local_audio_track,
                local_video_track
              ]
            }

            try {
                const connected_room = await connect(token, connectOptions)
        
                if (connected_room) {
                    roomJoined(connected_room)
                }
            } catch (error) {
                toast.error(
                    <CustomToast
                        title='Não foi possível conectar à chamada'
                        message={error.message}
                    />
                )
            }
        }
        setIsConnectingRoom(false)
    }

    const createTracks = async () => {
      const tracks = await createLocalTracks({
          audio: true,
          video: {width: 640}
      })
      const newVideoTrack = tracks.find(track => track.kind === 'video')
      const newAudioTrack = tracks.find(track => track.kind === 'audio')
      if (newVideoTrack) {
          setLocalVideoTrack(newVideoTrack)
          setIsLocalVideoTrackEnabled(true)
          if (localMedia.current) {
            localMedia.current.innerHTML = ''
            localMedia.current.appendChild(newVideoTrack.attach())
          }
          if (doctorLocalMedia.current) {
            doctorLocalMedia.current.innerHTML = ''
            doctorLocalMedia.current.appendChild(newVideoTrack.attach())
          }
      }

      if (newAudioTrack) {
          setLocalAudioTrack(newAudioTrack)
          setIsLocalAudioTrackEnabled(true)
      }

      return tracks
    }

    const roomJoined = room => {
        setActiveRoom(room)
        startTimer()

        room.participants.forEach(participantConnected)

        room.on('participantConnected', participantConnected)

        room.on('participantDisconnected', participant => {
            console.log('on participantDisconnected', participant)
        })
        
        room.localParticipant.on('trackPublished', publication => {
            console.log(`Published LocalTrack: ${publication.track}`)
        })
        
        room.localParticipant.on('trackUnpublished', publication => {
            console.log(`Published LocalTrack: ${publication.track}`)
        })
    }

    const participantConnected = participant => {
        setIdentity(participant.identity)

        participant.tracks.forEach(trackPublished)
    
        participant.on('trackPublished', trackPublished)
    
        participant.on('trackUnpublished', publication => {
            console.log('on trackUnpublished')
        })      
    }

    const trackPublished = publication => {
      
        if (publication.isSubscribed) {
            participantSubscribe(publication.track)  
        }

        publication.on('subscribed', participantSubscribe)
      
        publication.on('unsubscribed', participantUnsubscribe)
    }    

    const participantSubscribe = track => {
      addTrackEvents(track)
      if (track.name === 'screen_share') {
        screenMedia?.current.appendChild(track.attach())  
        if (doctorScreenMedia.current) {
            doctorScreenMedia?.current.appendChild(track.attach())  
        }
        setIsRemoteScreenEnabled(true)
      }
      else {
        remoteMedia?.current.appendChild(track.attach()) 
        if (doctorRemoteMedia.current) {
            doctorRemoteMedia?.current.appendChild(track.attach()) 
        }
      }

      if (track.kind === 'audio' && track.isEnabled) {
          setIsRemoteMediaAudioEnabled(true)
      }   
      if (track.kind === 'video' && track.isEnabled) {
          setIsRemoteMediaVideoEnabled(true)
      }  
    }

    const participantUnsubscribe = track => {
        if (track.name === 'screen_share') {
            setIsRemoteScreenEnabled(false)
            screenMedia.current.innerHTML = ''
            
            if (doctorScreenMedia.current) {
                doctorScreenMedia.current.innerHTML = ''
            }
        } else {
            remoteMedia.current.innerHTML = ''
            if (doctorRemoteMedia.current) {
                doctorRemoteMedia.current.innerHTML = ''
            }
            setIsRemoteMediaVideoEnabled(false)
        }
    }

    const addTrackEvents = track => {
        track.on('disabled', (track) => {
            if (track.kind === 'audio') {
                setIsRemoteMediaAudioEnabled(false)
            }
            if (track.kind === 'video') {
                setIsRemoteMediaVideoEnabled(false)
            }
        })
        track.on('enabled', (track) => {
            if (track.kind === 'audio') {
                setIsRemoteMediaAudioEnabled(true)
            }
            if (track.kind === 'video') {
                setIsRemoteMediaVideoEnabled(true)
            }
        }) 
    }

    const toggleVideoTrack = () => {
        if (activeRoom) {
            activeRoom.localParticipant.videoTracks.forEach(publication => {
                if (publication.track.isEnabled) {
                    publication.track.disable()
                    setIsLocalVideoTrackEnabled(false)
                } else {
                    publication.track.enable()
                    setIsLocalVideoTrackEnabled(true)
                }
            })
        } else if (local_video_track) {
            if (local_video_track.isEnabled) {
                local_video_track.disable()
                setIsLocalVideoTrackEnabled(false)
            }
            else {
                local_video_track.enable()
                setIsLocalVideoTrackEnabled(true)
            }
        }
    }

    const toggleAudioTrack = () => {
        if (activeRoom) {
            activeRoom.localParticipant.audioTracks.forEach(publication => {
                if (publication.track.isEnabled) {
                    publication.track.disable()
                    setIsLocalAudioTrackEnabled(false)
                } else {
                    publication.track.enable()
                    setIsLocalAudioTrackEnabled(true)
                }
            }) 
        } else if (local_audio_track) {
            if (local_audio_track.isEnabled) {
                local_audio_track.disable()
                setIsLocalAudioTrackEnabled(false)
            } else {
                local_audio_track.enable()
                setIsLocalAudioTrackEnabled(true)
            }
        }
    }   

    const addVideoTracksFromActiveRoom = activeRoom => {
        activeRoom.participants.forEach(participant => {
            participant.tracks.forEach(track => {
                if (track.kind === 'video') {
                    if (track.trackName === 'screen_share') {
                        screenMedia.current.innerHTML = ''
                        screenMedia.current.appendChild(track.track.attach())
                        
                        if (doctorScreenMedia.current) {
                            doctorScreenMedia.current.innerHTML = ''
                            doctorScreenMedia.current.appendChild(track.track.attach())
                        }
                    } else {
                        remoteMedia.current.innerHTML = ''
                        remoteMedia.current.appendChild(track.track.attach())
                        
                        if (doctorRemoteMedia.current) {
                            doctorRemoteMedia.current.innerHTML = ''
                            doctorRemoteMedia.current.appendChild(track.track.attach())
                        }
                    }
                }
            })
        })
      
        activeRoom.localParticipant.tracks.forEach(track => {
            if (track.kind === 'video') {
                if (track.trackName === 'screen_share') {
                    screenMedia.current.innerHTML = ''
                    screenMedia.current.appendChild(track.track.attach())
                    
                    if (doctorScreenMedia.current) {
                        doctorScreenMedia.current.innerHTML = ''
                        doctorScreenMedia.current.appendChild(track.track.attach())
                    }
                } else {
                    localMedia.current.innerHTML = ''
                    localMedia.current.appendChild(track.track.attach())

                    if (doctorLocalMedia.current) {
                        doctorLocalMedia.current.innerHTML = ''
                        doctorLocalMedia.current.appendChild(track.track.attach())
                    }
                }
            }
        })
    }

    const changeDoctorVideoRefs = is_full_screen => {
        if (activeRoom && is_doctor) {
            if (is_full_screen) {
                doctorLocalMedia.current.innerHTML = ''
                doctorRemoteMedia.current.innerHTML = ''
                activeRoom.localParticipant.videoTracks.forEach(publication => {
                    localMedia.current.innerHTML = ''
                    localMedia.current.appendChild(publication.track.attach())
                })
                activeRoom.localParticipant.audioTracks.forEach(publication => {
                    localMedia.current.appendChild(publication.track.attach())
                }) 
                activeRoom.participants.videoTracks?.forEach(publication => {
                    remoteMedia.current.innerHTML = ''
                    remoteMedia.current.appendChild(publication.track.attach())
                })
                activeRoom.participants.audioTracks?.forEach(publication => {
                    remoteMedia.current.appendChild(publication.track.attach())
                }) 
            }
            else {
                localMedia.current.innerHTML = ''
                remoteMedia.current.innerHTML = ''
                activeRoom.localParticipant.videoTracks.forEach(publication => {
                    doctorLocalMedia.current.innerHTML = ''
                    doctorLocalMedia.current.appendChild(publication.track.attach())
                })
                activeRoom.localParticipant.audioTracks.forEach(publication => {
                    doctorLocalMedia.current.appendChild(publication.track.attach())
                }) 
                activeRoom.participants.videoTracks?.forEach(publication => {
                    doctorRemoteMedia.current.innerHTML = ''
                    doctorRemoteMedia.current.appendChild(publication.track.attach())
                })
                activeRoom.participants.audioTracks?.forEach(publication => {
                    doctorRemoteMedia.current.appendChild(publication.track.attach())
                }) 
            }
        }
    }

    const shareScreen = async () => {
        try {
            const stream = await navigator.mediaDevices.getDisplayMedia()

            const screen_track = new LocalVideoTrack(stream.getTracks()[0], {name:'screen_share'})
            activeRoom.localParticipant.publishTrack(screen_track)
            screenMedia.current.appendChild(screen_track.attach())

            if (doctorScreenMedia.current) {
                doctorScreenMedia.current.appendChild(screen_track.attach())
            }
            setLocalScreenTrack(screen_track)
            setIsLocalScreenTrackEnabled(true)
            screen_track.mediaStreamTrack.onended = () => disableScreenShare(screen_track)

        } catch (error) {
            if (error.message === 'Permission denied') {
                console.log(error)
            } else {
                toast.error(
                    <CustomToast
                        title='Atenção'
                        message={error}
                    />
                )
            }
        }
    }

    const disableScreenShare = screen_track => {
        if (screen_track) {
            activeRoom.localParticipant.unpublishTrack(screen_track)
            screen_track.stop()
        } else {
            activeRoom.localParticipant.unpublishTrack(local_screen_track)
            local_screen_track.stop()
        }
        setLocalScreenTrack(null)
        setIsLocalScreenTrackEnabled(false)
        
        screenMedia.current.innerHTML = ''
        if (doctorScreenMedia.current) {
            doctorScreenMedia.current.innerHTML = ''
        }
    }

    const record = () => {
        //setIsRecording(true)
        startStop(true);
    }

    const stopRecord = () => {
        //setIsRecording(false)
        startStop(false)
    }

    const startStop = async (record) => {
        try {
            const response = await startStopRecord(room?.sid, record)
            if (response.status === 200) {
                setIsRecording(record)
            }
        } catch (error) {
            setIsRecording(false)
        }
    }

    return (
      <Teleatendimento.Provider 
        value={{ 
          activeRoom, 
          setActiveRoom,
          timer, 
          roomNameErr,
          identity,
          local_video_track,
          is_local_video_track_enabled,
          setIsLocalVideoTrackEnabled,
          local_audio_track,
          is_local_audio_track_enabled,
          setIsLocalAudioTrackEnabled,
          is_local_screen_track_enabled, 
          setIsLocalScreenTrackEnabled,
          is_remote_media_audio_enabled,
          is_remote_media_video_enabled,
          is_remote_screen_enabled,

          setRoom,
          setToken,

          disconnect,
          is_room_disconnected,
          setIsRoomDisconnected,
          createTracks,
          toggleVideoTrack,
          toggleAudioTrack,

          addVideoTracksFromActiveRoom,
          localMedia,
          remoteMedia,
          screenMedia,
          screenMediaRef,
          doctorLocalMedia,
          doctorRemoteMedia,
          doctorScreenMedia,
          disableScreenShare,
          shareScreen,

          is_full_screen, 
          setIsFullScreen,
          changeDoctorVideoRefs,

          is_recording,
          record,
          stopRecord,

          is_doctor, 
          setIsDoctor,

          is_connecting_room,

          url, 
          setUrl,

          urlPaciente,
          setUrlPaciente,

          setRoomUniqueName,
          roomUniqueName,

          urlWhereby,
          urlPacienteWhereby,
          roomUniqueNameWhereby,
          setUrlWhereby,
          setUrlPacienteWhereby,
          setRoomUniqueNameWhereby,
          activeRoomWhereby, 
          setActiveRoomWhereby,
          meetingId, 
          setMeetingId
        }}
      >
        {children}
      </Teleatendimento.Provider>
    ) 
}

export { Teleatendimento, TeleatendimentoContext }
