// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'
import { atualizarUnidadePadrao, definirPermissaoUnidade, obterEmpresasColaboradorPorUsuarioId } from '../services/Empresa'
import { finalizar } from '../views/atendimento/prescricoes/memed'

const config = useJwt.jwtConfig

const initialUser = () => {
  const item = window.localStorage.getItem('userData')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {}
}

const initialEmpresas = () => {
  const item = window.localStorage.getItem('empresas')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : []
}

const initialUnidades = () => {
  const item = window.localStorage.getItem('unidades')
  //** Parse stored json or if none return initialValue
  return item && JSON.parse(item) ? JSON.parse(item) : []
}

const initialColaborador = () => {
  const item = window.localStorage.getItem('colaborador')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {}
}

const initialEmpresaSelecionada = () => {
  const item = window.localStorage.getItem('empresaSelecionada')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {}
}

const initialUnidadeSelecionada = () => {
  const item = window.localStorage.getItem('unidadeSelecionada')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {}
}

export const definirUnidadePadrao = createAsyncThunk('unidadePadrao/atualizar', async (model, { dispatch, getState }) => {
  await atualizarUnidadePadrao(model?.colaboradorId, model?.unidadeId)
  dispatch(handleSelectUnidade(model))
  location.reload();
})

export const obterEmpresasUnidadesPorUsuario = createAsyncThunk('empresas/unidades', async (model, { dispatch, getState }) => {
  var result = await obterEmpresasColaboradorPorUsuarioId();
  return handleEmpresaColaboradorActions(result.data, dispatch);
})

export const atualizarPermissoesUnidades = createAsyncThunk('unidades/permissoes', async (model, { dispatch, getState }) => {
  var result = await definirPermissaoUnidade(model);
  dispatch(obterEmpresasUnidadesPorUsuario())
})

const handleEmpresaColaboradorActions = (data, dispatch) => {
  const empresaPadrao = data?.empresas?.find(e => e?.empresaPadrao);
  const empresasDropdown = data?.empresas;
  const unidadePadrao = empresaPadrao?.colaboradorUnidades?.find(u => u?.unidadePadrao);
  const unidadesDropdown = empresaPadrao?.colaboradorUnidades

  dispatch(handleSelectEmpresa(empresaPadrao))
  dispatch(handleEmpresas(empresasDropdown))

  dispatch(handleUnidades(unidadesDropdown))

  if (!unidadePadrao) {
    const model = {
      ...unidadesDropdown[0].unidade,
      colaboradorId: data?.colaborador?.id,
      unidadeId: unidadesDropdown[0].unidade?.id
    }
    dispatch(definirUnidadePadrao(model))
  } else {
    dispatch(handleSelectUnidade(unidadePadrao.unidade))
  }
  dispatch(handleColaborador(data?.colaborador))
  return data;
}

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    userData: initialUser(),
    empresas: initialEmpresas(),
    unidades: initialUnidades(),
    colaborador: initialColaborador(),
    empresaSelecionada: initialEmpresaSelecionada(),
    unidadeSelecionada: initialUnidadeSelecionada()
  },
  reducers: {
    handleLogin: (state, action) => {
      state.userData = action.payload
      state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName]
      state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName]
      localStorage.setItem('userData', JSON.stringify(action.payload))
      localStorage.setItem(config.storageTokenKeyName, action.payload.accessToken)
      localStorage.setItem(config.storageRefreshTokenKeyName, action.payload.refreshToken)
    },
    handleEmpresas: (state, action) => {
      state.empresas = action.payload
      if (action.payload) localStorage.setItem('empresas', JSON.stringify(action.payload))
    },
    handleUnidades: (state, action) => {
      state.unidades = action.payload
      if (action.payload) localStorage.setItem('unidades', JSON.stringify(action.payload))
    },
    handleColaborador: (state, action) => {
      state.colaborador = action.payload
      if (action.payload) localStorage.setItem('colaborador', JSON.stringify(action.payload))
    },
    handleSelectEmpresa: (state, action) => {
      state.empresaSelecionada = action.payload
      if (action.payload) localStorage.setItem('empresaSelecionada', JSON.stringify(action.payload))
    },
    handleSelectUnidade: (state, action) => {
      state.unidadeSelecionada = action.payload
      if (action.payload) localStorage.setItem('unidadeSelecionada', JSON.stringify(action.payload))
    },
    handleLogout: state => {
      state.userData = {}
      state[config.storageTokenKeyName] = null
      state[config.storageRefreshTokenKeyName] = null
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem('atendimento')
      localStorage.removeItem('userData')
      localStorage.removeItem('empresas')
      localStorage.removeItem('unidades')
      localStorage.removeItem('colaborador')
      localStorage.removeItem('empresaSelecionada')
      localStorage.removeItem('unidadeSelecionada')
      localStorage.removeItem(config.storageTokenKeyName)
      localStorage.removeItem(config.storageRefreshTokenKeyName)
    }
  }
})

export const { handleLogin, handleEmpresas, handleUnidades, handleColaborador, handleSelectEmpresa, handleSelectUnidade, handleLogout } = authSlice.actions

export default authSlice.reducer
