import { obterApi } from "./Api"

const api = () => {
    return obterApi(process.env.REACT_APP_API_URL + '/Agenda')
}

export const listarRecursosAgenda = async (idEmpresa) => {
    return api().get(`listarRecursos?idEmpresa=${idEmpresa}`)
}

export const GraficoHomeAgendas = async (idUnidade) => {
    return api().get(`grafico?idUnidade=${idUnidade}`)
}

export const atualizarRecurso = async (model) => {
    return api().post(`alterarRecurso`, model)
}

export const cadastrarRecurso = async (model) => {
    return api().post(`salvarRecurso`, model)
}

export const obterRecursoAgenda = async (id) => {
    return api().get(`obterRecurso?id=${id}`)
}

export const situacaoRecursoAgenda = async (id, situacao) => {
    return api().get(`situacaoRecurso?id=${id}&situacao=${situacao}`)
}

export const AgendarPaciente = async (model) => {
    return api().post(`salvar`, model)
}

export const AtualizarCarteiraAgenda = async (carteiraId, agendaId) => {
    return api().get(`atualizar-carteira-agenda/${carteiraId}/${agendaId}`)
}

export const AgendarPacienteListaEspera = async (model) => {
    return api().post(`agendar-lista-espera`, model)
}

export const ExcluirEventoBloqueioAgenda = async (id) => {
    return api().get(`excluirEventoBloqueioAgenda?id=${id}`)
}

export const AgendaPorAtendimento = async (id) => {
    return api().get(`agenda-atendimento/${id}`)
}

export const ExcluirEventoBloqueioAgendaAllDay = async (idAgenda, idBloqueio) => {
    return api().get(`excluirEventoBloqueioAgendaAllDay/${idAgenda}/${idBloqueio}`)
}

export const AlterarSituacaoAgenda = async (id, situacao) => {
    return api().get(`alterarSituacaoAgenda?id=${id}&situacao=${situacao}`)
}

export const UpdateAgendaOnDragPaciente = async (model) => {
    return api().post(`atualizarOnDrag`, model)
}

export const EnviarAvisoPorSMS = async (model) => {
    return api().post(`aviso-sms`, model)
}

export const UpdateAgendaPaciente = async (model) => {
    return api().post(`atualizar`, model)
}

export const CancelarAgendamento = async (id) => {
    return api().get(`cancelar?id=${id}`)
}

export const AgendadosDoDia = async (model) => {
    return api().post(`agendados`, model)
}

export const ChegadaPaciente = async (id) => {
    return api().get(`chegada?id=${id}`)
}

export const MontarHorarios = async (model) => {
    return api().post(`gerarHorarios`, model)
}

export const DiasBloqueadosAgenda = async (idColaborador, idUnidade) => {
    return api().get(`dias-em-bloqueio?idColaborador=${idColaborador}&idUnidade=${idUnidade}`)
}

export const ConfiguracaoAtendimentoAgenda = async (idColaborador) => {
    return api().get(`listarDiasAtendimento?idColaborador=${idColaborador}`)
}

export const AddDiasAtendimento = async (model) => {
    return api().post(`salvarDiasAtendimento`, model)
}

export const AlterarDiasAtendimento = async (model) => {
    return api().post(`alterarDiasAtendimento`, model)
}

export const ObterAgendasPorData = async (model) => {
    return api().post(`calendario`, model)
}

export const ObterAgendasPorDataPesquisaPaciente = async (model) => {
    return api().post(`calendarioPorPaciente`, model)
}

export const ListarTarefasPorUnidade = async (idUnidade) => {
    return api().get(`listarTarefasUnidade?idUnidade=${idUnidade}`)
}

export const SalvarTarefa = async (model) => {
    return api().post(`salvarTarefaAgenda`, model)
}
export const ListarTarefasPorColaborador = async (idColaborador) => {
    return api().get(`listarTarefasColaborador?idColaborador=${idColaborador}`)
}

export const AlterarTarefa = async (model) => {
    return api().post(`alterarTarefaAgenda`, model)
}

export const ObterTarefa = async (id) => {
    return api().get(`obterTarefaAgenda?id=${id}`)
}

export const ExcluirTarefaAgenda = async (id) => {
    return api().get(`excluirTarefaAgenda?id=${id}`)
}

export const ConcluirTarefa = async (id) => {
    return api().get(`concluirTarefaAgenda?id=${id}`)
}

export const ConcluirTarefaAgenda = async (id) => {
    return api().get(`concluirTarefaAgenda?id=${id}`)
}

export const DesConcluirTarefaAgenda = async (id) => {
    return api().get(`desConcluirTarefaAgenda?id=${id}`)
}

// label tarefasexport const ObterTarefa = async (id) => {
export const ObterLabelPorId = async (id) => {
    return api().get(`obterLabel?id=${id}`)
}
export const ExcluirLabel = async (id) => {
    return api().get(`excluirLabel?id=${id}`)
}
export const SalvarLabel = async (model) => {
    return api().post(`salvarLabel`, model)
}
export const ListarLabelAtivas = async (idEmpresa) => {
    return api().get(`listarLabel?idEmpresa=${idEmpresa}`)
}
export const LabelSidebar = async (idEmpresa) => {
    return api().get(`labelSidebar?idEmpresa=${idEmpresa}`)
}

export const AtualizarLabel = async (model) => {
    return api().post(`atualizarLabel`, model)
}

// bloqueio de agenda
export const ObterBloqueioAgenda = async (id) => {
    return api().get(`obterBloqueioAgenda?id=${id}`)
}
export const ExcluirBloqueioAgenda = async (id) => {
    return api().get(`excluirBloqueioAgenda?id=${id}`)
}
export const SalvarBloqueioAgenda = async (model) => {
    return api().post(`salvarBloqueioAgenda`, model)
}
export const ListarBloqueioUnidade = async (idUnidade) => {
    return api().get(`listarBloqueioUnidade?idUnidade=${idUnidade}`)
}
export const ListarBloqueioColaborador = async (idColaborador) => {
    return api().get(`listarBloqueioColaborador?idColaborador=${idColaborador}`)
}
export const AlterarBloqueioAgenda = async (model) => {
    return api().post(`alterarBloqueioAgenda`, model)
}
export const BloqueiosAgendaPorData = async (model) => {
    return api().post(`calendario-bloqueios`, model)
}

//Configurações da agenda

export const ListarConfiguracoesAgenda = async (idColaborador, idUnidade) => {
  return api().get(`configuracoes/${idColaborador}/${idUnidade}`)
}

export const EditarConfiguracaoAgenda = async (idColaborador, idUnidade, model) => {
  return api().put(`configurar/${idColaborador}/${idUnidade}`, model)
}

export const ConfigurarAgenda = async (model) => {
  return api().post('configurar', model)
}

export const ObterAgendaColaborador = async (data, isSocial, idColaborador) => {
    return api().get( `agenda-medica?data=${data}&social=${isSocial}&idColaborador=${idColaborador}`)
}