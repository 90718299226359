// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import loader from './loader'
import procedimentos from '@src/views/configuracoes/procedimentos/store/reducer'
import colaboradores from '@src/views/configuracoes/colaboradores/store/reducer'
import pacientes from '@src/views/pacientes/store/reducer'
import tabelas from '@src/views/configuracoes/tabelas/store/reducer'
import unidades from '@src/views/configuracoes/unidades/store/reducer'
import recursos from '@src/views/configuracoes/recursos-agenda/store/reducer'
import prontuarios from '@src/views/configuracoes/prontuarios/store/reducer'
import modelos from '@src/views/configuracoes/modelo-documentos/store/reducer'
import registroPlataforma from '@src/views/autenticacao/store'
import atendimento from '@src/views/atendimento/reducer'
import agenda from '@src/views/agenda/reducer'
import empresa from '../components/modals/clinic-config-modal/reducer'
import orcamentos from '@src/views/pacientes/orcamentos/store/reducer'


const rootReducer = {
  auth,
  agenda,
  navbar,
  layout,
  loader,
  procedimentos,
  colaboradores,
  pacientes,
  tabelas,
  unidades,
  recursos,
  prontuarios,
  modelos,
  registroPlataforma,
  atendimento,
  empresa,
  orcamentos
}

export default rootReducer
