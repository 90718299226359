import axios from 'axios'
import { toast } from 'react-toastify';

import jwt from '../auth/jwt/useJwt';
import { handleLogout } from '../redux/authentication';
import { handleSkin } from '../redux/layout';
import { store } from '../redux/store';

export const renovarToken = async () => {
  const refreshToken = jwt.getRefreshToken();
  
  let axiosConfig = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }
  };
  return axios.post(jwt.jwtConfig.refreshEndpoint, `"${refreshToken}"`, axiosConfig)
}

export const obterApi = (url) => {

  const Api = axios.create({ baseURL: `${url}` })

  Api.interceptors.request.use(
    async (config) => {
      config.baseURL = `${url}`
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  Api.interceptors.request.use(async (config) => {
    return new Promise((resolve) => setTimeout(() => resolve(config), 200))
  })

  Api.interceptors.request.use(async (config) => {
    const token = jwt.getToken() ?? false
    if (token) config.headers.Authorization = `Bearer ${token}`
    return config
  })

  Api.interceptors.response.use(
    (response) => {
      return response
    },
    async (error) => {

      const { config, response } = error
      const originalRequest = config

      if (response && response.status === 401) {
        try {
          store.dispatch(handleSkin('light'))
          store.dispatch(handleLogout())
          window.location.href = '/login';
          return false
          // const response = await renovarToken();
          // const { accessToken, refreshToken } = response.data

          // jwt.setToken(accessToken)
          // jwt.setRefreshToken(refreshToken)
  
          // axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
          // return Api(originalRequest);
          
        } catch (error) {
          store.dispatch(handleSkin('light'))
          store.dispatch(handleLogout())
          window.location.href = '/login';
          return false
        }
      }
      return Promise.reject(error)
    }
  );

  return Api
}

const getResp = async (label, resp) => {
  if (resp) {
    // const content_type = resp.headers.get("Content-Type");    
    // if (content_type.includes("pdf")) {
    //   if (resp.status === 200) await getFilePDF(resp);      
    // } else if (content_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
    //   if (resp.status === 200) await getFileXLS(resp);
    // } else if (content_type.includes("text/html")) {
    //   if (resp.status === 200) await getFileHTML(resp);
    // } else {
    if (resp.status > 300) {
      if (resp.status === 401){
        localStorage.removeItem('userData')
        localStorage.removeItem('empresas')
        localStorage.removeItem('unidades')
        localStorage.removeItem('colaborador')
        localStorage.removeItem('empresaSelecionada')
        localStorage.removeItem('unidadeSelecionada')
        window.location.href = '/login';
      }
      if (json && json.message && json.message !== '') {
        
        if (!toast.isActive('toastDefaultError')) toast.error(`Erro no ${label}: `+json.message, {autoClose: false, toastId: 'toastDefaultError'});
        else toast.update('toastDefaultError', {render: `Erro no ${label}: `+json.message, autoClose: false});
        
        if (json.ValidationErrors) {
          return json
        }
      } 
    } 
    else return resp.data;
    // }
  } 
  return null;
}

export const getReq = async (label, api_url, url) => {
  if (navigator.onLine) {
    try {  
      const url_completa = process.env.REACT_APP_API_URL + api_url;

      let resp = await obterApi(url_completa).get(url);
      // console.log('resp', resp)
      return await getResp(label, resp); 
    } catch(e) {
      console.log(`Erro ao carregar ${label}: `+e);
    }
  } 
  else toast.error('Sem conexão com a Internet.', {autoClose: false, toastId: 'toastError'});
  return null;
} 

export const postReq = async (label, api_url, url, body) => {
  if (navigator.onLine) {
    try {
      const url_completa = process.env.REACT_APP_API_URL + api_url;
      
      let resp = await obterApi(url_completa).post(url, body);
      
      return await getResp(label, resp);
    } catch(e) {
      console.log(`Erro ao criar ${label}: `+e);
    }
  } else toast.error('Sem conexão com a Internet.', {autoClose: false, toastId: 'toastError'});
  return null;
}

export const putReq = async (label, api_url, url, body) => {
  if (navigator.onLine) {
    try {
      const url_completa = process.env.REACT_APP_API_URL + api_url;
      
      let resp = await obterApi(url_completa).put(url, body);
      
      return await getResp(label, resp);
    } catch(e) {
      console.warn(`Erro ao atualizar ${label}: `+e);
    }
  } else toast.error('Sem conexão com a Internet.', {autoClose: false, toastId: 'toastError'});
  return null;
}

export const deleteReq = async (label, api_url, url) => {
  if (navigator.onLine) {
    try{
      const url_completa = process.env.REACT_APP_API_URL + api_url;
      
      let resp = await obterApi(url_completa).delete(url);

      if (resp.status !== 200) return await resp.json();
    }catch(e){
      console.warn(`Erro ao remover ${label}: `+e);
    }
  } else toast.error('Sem conexão com a Internet.', {autoClose: false, toastId: 'toastError'});
  return null;
}

// export const sendFile = async (label, url, body, check) => {
//   try{
//     let headers = setHeaders('', check);
    
//     let reqParams = {
//       method: "POST",
//       headers,
//       body
//     };

//     let urlCompleta = CONFIG.URL + url;
//     let resp = await fetch(urlCompleta, reqParams);
    
//     return await getResp(label, resp);
//   }catch(e){
//     console.warn(`Erro ao enviar imagem do ${label}: `+e);
//   }
// }